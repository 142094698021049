*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
@import 'materialize-css/dist/css/materialize.min.css';
@import 'materialize-css';
@import './variables';

// @media only screen and (max-width: 1025px) {
//   .main {
//     background-color: green;
//   }
//************************** animated background
// @keyframes fadeOutUp {
//   from {
//     opacity: 1;
//   }

//   to {
//     opacity: 0;
//     transform: translate3d(0, -100%, 0);
//   }
// }

// .fadeOutUp {
//   animation-name: fadeOutUp;
// }
body {
  // background: linear-gradient(-45deg, #ee7652d2, #e73c7e93, #23a5d578, #2367d583);
  background: linear-gradient(-45deg, #09072ade, #0c0838de, #e73c7e93, #36c6fac9);
  background-size: 300% 300%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
//************************** All

.All {
  margin: 0;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

//************************** Ramdom Elements

.title {
  font-size: 4rem;
  font-weight: 700;
}
@media only screen and (max-width: 1025px) {
  .title {
    font-size: 1.4rem;
    font-weight: 700;
  }
}

.tent {
  z-index: 100;
  height: 25%;
  width: 35%;
  position: absolute;
  top: 48%;
  left: 32%;

  &-triangle {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    position: absolute;
    background-color: rgba(100, 236, 152, 0.835);
  }
  &-frontWall {
    height: 200%;
    width: 80%;
    border-radius: 50%;
    position: absolute;
    top: -60%;
    left: 45%;
    background-color: rgba(52, 116, 76, 0.719);
  }
  &-door {
    height: 30%;
    width: 35%;
    border-radius: 50%;
    transform: skewY(38deg);
    position: absolute;
    top: 55%;
    left: 10%;
    background-color: rgba(10, 22, 15, 0.856);
  }
}
.sign {
  z-index: 11;
  height: 17%;
  width: 13%;
  position: absolute;
  top: -28%;
  left: 42%;
  animation: arrowWiggle 2s alternate ease infinite;

  &-arrow {
    height: 100%;
    width: 100%;
    border-radius: 20%;
    background-color: crimson;
    border-inline-color: black;
    position: absolute;
    background-color: rgba(158, 231, 162, 0.479);
    clip-path: polygon(73% 0, 70% 60%, 100% 61%, 49% 100%, 0 60%, 30% 60%, 23% 0);
  }
  // &-half{
  //   height: 100%;
  //   width: 100%;
  //   border-radius: 20%;
  //   position: absolute;
  //   background-color: rgb(6, 6, 31);
  //   clip-path: polygon(50% 51%, 50% 32%, 100% 0, 100% 50%, 51% 100%, 50% 80%);
  // }
}
@keyframes arrowWiggle {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-90px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
//************************** Welcome Route

.animationField {
  position: relative;
  top: 0%;
  height: 70%;
  width: 70%;
}

//************* astral Field
.astralField {
  // background-color: chartreuse;
  height: 95%;
  width: 85%;
  transform: rotate(-25deg);
  position: absolute;
  left: 6%;
  top: -10%;
  animation: astralRotation 7s alternate ease-in-out infinite;
}
@keyframes astralRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(100deg);
  }
}
.moon {
  height: 20%;
  width: 20%;
  border-radius: 50%;
  bottom: 15%;
  left: 0%;
  overflow: hidden;
  position: absolute;
  &-moonBody {
    height: 100%;
    width: 100%;
    background-color: #fcffa8a4;
  }
  &-moonHole {
    height: 65%;
    width: 65%;
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    background-color: #33325d;
  }
}

.sun {
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background: linear-gradient(-45deg, red, rgb(255, 102, 0), yellow);
  box-shadow: 0px 0px 80px red;
  position: absolute;
  right: 15%;
  top: 0%;
}
@media only screen and (max-width: 1025px) {
  .startButton {
    height: 17%;
    width: 28%;
    top: 13%;
    left: 63%;
  }
}

//************* Base
.FloatingBase {
  height: 70%;
  width: 40%;
  position: absolute;
  top: 10%;
  left: 25%;
  animation: baseFloat 15s infinite ease-in-out alternate;

  &-field {
    height: 80%;
    width: 100%;
    position: absolute;
    top: 12%;
    clip-path: polygon(46% 19%, 100% 56%, 56% 100%, 0 64%);
    background-color: rgb(8, 156, 170);
  }
  &-leftTopBase {
    height: 40%;
    width: 62.6%;
    position: absolute;
    top: 55%;
    left: -2.5%;
    transform: rotate(11deg);
    clip-path: polygon(2% 39%, 97% 78%, 100% 100%, 0 55%);
    background-color: rgba(24, 24, 59, 0.924);
  }
  &-leftBottomBase {
    height: 20%;
    width: 71.5%;
    position: absolute;
    top: 86%;
    left: -8%;
    transform: rotate(22deg);
    clip-path: polygon(90% 19%, 0 0, 100% 100%);
    background-color: rgba(24, 24, 59, 0.596);
  }
  @media only screen and (max-width: 1025px) {
    &-leftTopBase {
      height: 40%;
      width: 63.5%;
      top: 55%;
      left: -2.8%;
      transform: rotate(12deg);
      clip-path: polygon(2% 39%, 97% 80%, 100% 100%, 0 55%);
    }
    &-leftBottomBase {
      height: 20%;
      width: 75%;
      top: 88%;
      left: -9%;
      transform: rotate(26deg);
      -webkit-clip-path: polygon(92.5% 10%, 0 0, 100% 100%);
      clip-path: polygon(85% 10%, 0 0, 100% 100%);
    }
  }
  &-rightTopBase {
    height: 40%;
    width: 56%;
    position: absolute;
    top: 48.9%;
    right: -2.6%;
    transform: rotate(-27deg);
    clip-path: polygon(5% 80%, 100% 58%, 98% 75%, 0 100%);
    background-color: #8ecbe0f3;
  }
  &-rightBottomBase {
    height: 31.5%;
    width: 58%;
    position: absolute;
    top: 77.2%;
    right: -9.6%;
    transform: rotate(-22deg);
    clip-path: polygon(100% 0, 8% 46%, 0 100%);
    background-color: rgba(59, 59, 177, 0.383);
  }
  @media only screen and (max-width: 1025px) {
    &-rightTopBase {
      height: 40%;
      width: 60%;
      top: 49.8%;
      // right: -%;
      transform: rotate(-30.5deg);
      clip-path: polygon(7.5% 80%, 100% 58%, 98% 75%, 0% 100%);
    }
    &-rightBottomBase {
      height: 36%;
      width: 63%;
      top: 76.5%;
      right: -12%;
      transform: rotate(-23deg);
      -webkit-clip-path: polygon(100% 0, 6% 48%, 0 100%);
      clip-path: polygon(100% 0%, 11% 48%, 0 100%);
    }
  }
}

@media only screen and (max-width: 1025px) {
  .FloatingBase {
    height: 55%;
    width: 100%;
    top: 25%;
    left: 0%;
  }
}
@keyframes baseFloat {
  0% {
    transform: translateY(5%);
  }
  50% {
    transform: translateY(-10%);
  }
}

//************* Mountains
.mountainRange {
  width: 80%;
  height: 50%;
  position: absolute;
  top: 5%;
  left: 10%;
}
.mediumRock {
  z-index: 15;
  height: 15%;
  width: 20%;
  position: absolute;
  right: 0%;
  bottom: -10%;
  &-borderTop {
    height: 100%;
    width: 70%;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: 25%;
  }
}
.mediumRockBottom {
  height: 90%;
  width: 150%;
  border-radius: 50%;
  background-color: #0a516b;
  position: absolute;
  left: -25%;
  bottom: 18%;
  &-shine {
    height: 10%;
    width: 17%;
    border-radius: 50%;
    position: absolute;
    right: 25%;
    bottom: 55%;
    transform: skewY(20deg);
    background-color: #1b8bb4;
  }
}

.bigRock {
  z-index: 13;
  height: 20%;
  width: 25%;
  position: absolute;
  right: -10%;
  bottom: -6%;
  &-borderTop {
    height: 100%;
    width: 70%;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: 25%;
  }
}
.bigRockBottom {
  height: 90%;
  width: 150%;
  border-radius: 50%;
  background-color: rgb(19, 19, 230);
  position: absolute;
  left: -25%;
  bottom: 18%;
  &-shine {
    height: 10%;
    width: 17%;
    border-radius: 50%;
    position: absolute;
    right: 25%;
    bottom: 55%;
    transform: skewY(20deg);
    background-color: rgb(128, 128, 248);
  }
}

.smallRock {
  z-index: 14;
  height: 10%;
  width: 15%;
  position: absolute;
  right: -10%;
  bottom: -10%;
  &-borderTop {
    height: 100%;
    width: 70%;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: 25%;
  }
}
.smallRockBottom {
  height: 90%;
  width: 150%;
  border-radius: 50%;
  background-color: #9bceeb;
  position: absolute;
  left: -25%;
  bottom: 18%;
  &-shine {
    height: 10%;
    width: 17%;
    border-radius: 50%;
    position: absolute;
    right: 25%;
    bottom: 55%;
    transform: skewY(20deg);
    background-color: #dcf2ff;
  }
}

.middleBush {
  z-index: 6;
  height: 20%;
  width: 18%;
  position: absolute;
  left: 55%;
  bottom: -4%;

  &-circle {
    height: 100%;
    width: 50%;
    border-radius: 50%;
    background-color: #2b795c;
    position: absolute;
    top: -3%;
    left: 25%;
    box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.623);
  }
  &-circle:nth-child(2) {
    height: 65%;
    width: 35%;
    top: 33%;
    left: 0%;
  }
  &-circle:nth-child(3) {
    height: 55%;
    width: 32%;
    top: 33%;
    left: 64%;
  }
  &-circle:nth-child(4) {
    height: 35%;
    width: 20%;
    top: 63%;
    left: 86%;
  }
  &-base {
    height: 25%;
    width: 80%;
    background-color: #2b795c;
    position: absolute;
    top: 73%;
    left: 16%;
  }
}

.leftBush {
  z-index: 11;
  height: 25%;
  width: 18%;
  position: absolute;
  left: -10%;
  bottom: -15%;

  &-circle {
    height: 100%;
    width: 50%;
    border-radius: 50%;
    background-color: #2b795c;
    position: absolute;
    top: -3%;
    left: 25%;
  }
  &-circle:nth-child(2) {
    height: 65%;
    width: 35%;
    top: 33%;
    left: 0%;
  }
  &-circle:nth-child(3) {
    height: 55%;
    width: 32%;
    top: 33%;
    left: 64%;
  }
  &-circle:nth-child(4) {
    height: 35%;
    width: 20%;
    top: 63%;
    left: 86%;
  }
  &-base {
    height: 25%;
    width: 80%;
    background-color: #2b795c;
    position: absolute;
    top: 73%;
    left: 16%;
  }
}

.pine {
  z-index: 11;
  height: 50%;
  width: 18%;
  position: absolute;
  left: 35%;
  bottom: -10%;

  &-foliage {
    z-index: 1;
    height: 25%;
    width: 40%;
    position: absolute;
    background-color: #275846;
    border-radius: 40%;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
  &-foliage:nth-child(2) {
    height: 20%;
    width: 60%;
    top: 15%;
    left: -10%;
  }
  &-foliage:nth-child(3) {
    height: 20%;
    width: 60%;
    top: 23%;
    left: -10%;
  }
  &-foliage:nth-child(4) {
    height: 28%;
    width: 80%;
    top: 30%;
    left: -20%;
  }
  &-foliage:nth-child(5) {
    height: 20%;
    width: 85%;
    top: 48%;
    left: -25%;
  }
  &-foliage:nth-child(6) {
    height: 30%;
    width: 95%;
    bottom: 20%;
    left: -27%;
  }
}
.rightPine {
  z-index: 4;
  height: 50%;
  width: 18%;
  position: absolute;
  left: 57%;
  bottom: 0%;
}
.lefMountain {
  z-index: 10;
  height: 75%;
  width: 40%;
  position: absolute;
  left: 0%;
  bottom: 0;

  &-mountain {
    z-index: 1;
    height: 100%;
    width: 100%;
    clip-path: polygon(37% 0, 61% 0, 100% 100%, 0% 100%);
    background-color: #137aa7;
  }
  &-half {
    z-index: 2;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0%;
    clip-path: polygon(49% 0, 61% 0, 100% 100%, 51% 100%);
    background-color: #13739c;
  }
  &-snow {
    z-index: 4;
    height: 50%;
    width: 50%;
    position: absolute;
    top: -15%;
    left: 22%;
  }
}
.middleMountain {
  z-index: 0;
  height: 100%;
  width: 50%;
  position: absolute;
  left: 25%;
  bottom: 15%;

  &-mountain {
    z-index: 1;
    height: 100%;
    width: 100%;
    clip-path: polygon(37% 0, 61% 0, 100% 100%, 0% 100%);
    background-color: darkblue;
  }
  &-half {
    z-index: 2;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0%;
    clip-path: polygon(49% 0, 61% 0, 100% 100%, 51% 100%);
    background-color: rgb(2, 2, 116);
  }
  &-snow {
    z-index: 4;
    height: 50%;
    width: 50%;
    position: absolute;
    top: -15%;
    left: 22%;
  }
}
.rightMountain {
  z-index: 5;
  height: 85%;
  width: 40%;
  position: absolute;
  right: 0%;
  bottom: 0%;

  &-mountain {
    z-index: 1;
    height: 100%;
    width: 100%;
    clip-path: polygon(37% 0, 61% 0, 100% 100%, 0% 100%);
    background-color: #00529c;
  }
  &-half {
    z-index: 2;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0%;
    clip-path: polygon(49% 0, 61% 0, 100% 100%, 51% 100%);
    background-color: #024783;
  }
  &-snow {
    z-index: 4;
    height: 50%;
    width: 50%;
    position: absolute;
    top: -15%;
    left: 22%;
  }
}

//************** clouds
.cloud {
  z-index: 18;
  height: 30%;
  width: 40%;
  position: absolute;
  top: 10%;
  left: 5%;
  animation: moveRight 15s infinite ease-in-out alternate;

  &-circle {
    height: 100%;
    width: 50%;
    border-radius: 50%;
    background-color: $cloud-color;
    position: absolute;
    top: -3%;
    left: 25%;
  }
  &-circle:nth-child(2) {
    height: 65%;
    width: 35%;
    top: 33%;
    left: 0%;
  }
  &-circle:nth-child(3) {
    height: 55%;
    width: 32%;
    top: 33%;
    left: 64%;
  }
  &-circle:nth-child(4) {
    height: 35%;
    width: 20%;
    top: 63%;
    left: 86%;
  }
  &-base {
    height: 25%;
    width: 80%;
    background-color: $cloud-color;
    position: absolute;
    top: 73%;
    left: 16%;
  }
}

.cloudRight {
  height: 20%;
  width: 20%;
  position: absolute;
  top: 10%;
  right: 5%;
  animation: moveLeft 15s infinite ease-in-out alternate;

  &-circle {
    height: 100%;
    width: 50%;
    border-radius: 50%;
    background-color: $cloud-color;
    position: absolute;
    left: 25%;
    top: -3%;
  }
  &-circle:nth-child(2) {
    height: 65%;
    width: 38%;
    top: 33%;
    left: 0%;
  }
  &-circle:nth-child(3) {
    height: 65%;
    width: 38%;
    top: 33.5%;
    left: 77.6%;
  }
  &-circle:nth-child(4) {
    height: 35%;
    width: 20%;
    top: 30%;
    left: 65%;
  }
  &-base {
    height: 35%;
    width: 80%;
    background-color: $cloud-color;
    position: absolute;
    top: 63%;
    left: 16%;
  }
}
@media only screen and (max-width: 1025px) {
  .cloud {
    height: 27%;
    width: 40%;
  }
  .cloudRight {
    height: 15%;
    width: 25%;
  }
}
@keyframes moveRight {
  0% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(50%);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(80%);
  }
  50% {
    transform: translateX(-55%);
  }
}

//************** Tree

.treeField {
  z-index: 11;
  height: 35%;
  width: 18%;
  position: absolute;
  bottom: 30%;
  left: 10%;

  &-trunk {
    background-color: brown;
    height: 50%;
    width: 11%;
    position: absolute;
    bottom: 4%;
    left: 70%;
  }
  &-roots {
    background-color: brown;
    height: 15%;
    width: 20%;
    position: absolute;
    bottom: 4%;
    left: 66%;
    clip-path: polygon(51% 54%, 65% 61%, 77% 69%, 90% 81%, 100% 100%, 50% 100%, 0 100%, 11% 76%, 21% 65%, 35% 59%);
  }

  &-branch {
    background-color: brown;
    height: 7%;
    width: 40%;
    position: absolute;
    bottom: 34%;
    left: 81%;
    transform: skewY(-30deg);
  }
  &-circle {
    height: 55%;
    width: 75%;
    border-radius: 50%;
    position: absolute;
    top: 18%;
    right: 0%;
    background-color: $drkCanopy-color;
    animation: canopyBack 10s infinite alternate;
  }
}

.canopy {
  z-index: 4;
  height: 60%;
  width: 100%;
  position: absolute;
  top: 0%;
  left: 15%;

  animation: canopyMov 10s infinite alternate;

  &-circle {
    height: 95%;
    width: 80%;
    border-radius: 50%;
    position: absolute;
    top: -3%;
    left: 25%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.376);
    background-color: $canopy-color;
  }
  &-circle:nth-child(2) {
    height: 45%;
    width: 38%;
    top: 33%;
    left: 0%;
    z-index: 2;
    background-color: $smallCanopy-color;
    animation: canopyPart 10s infinite alternate;
  }

  &-circle:nth-child(3) {
    z-index: 4;
    background-color: $mdmCanopy-color;
    height: 60%;
    width: 50%;
    top: 40%;
    left: 5%;
    animation: canopyPart 10s infinite alternate;
  }
  &-circle:nth-child(4) {
    z-index: 6;
    background-color: $mdm2canopy-color;
    height: 70%;
    width: 60%;
    top: 45%;
    left: 70%;
    animation: canopyEnd 10s infinite alternate;
  }
}

@keyframes canopyMov {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(15%);
  }
  30% {
    transform: translateX(13%);
  }
  40% {
    transform: translateX(15%);
  }
  50% {
    transform: translateX(11%);
  }
}
@keyframes canopyPart {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(18%);
  }
  20% {
    transform: translateX(10%);
  }
  30% {
    transform: translateX(25%);
  }
}
@keyframes canopyBack {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(20%);
  }
  30% {
    transform: translateX(15%);
  }
  40% {
    transform: translateX(23%);
  }
}
@keyframes canopyEnd {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(18%);
  }
  30% {
    transform: translateX(10%);
  }
  40% {
    transform: translateX(23%);
  }
}

//************************** Nav Bar

#SideNav-10 {
  background-color: rgba(245, 245, 245, 0.657) !important;
}
.user-view {
  height: 8rem;
}
.email {
  font-size: 2rem !important;
  font-weight: 600 !important;
  position: absolute;
  top: 80%;
  right: 15%;
}

.nav {
  background-color: rgba(245, 245, 245, 0) !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  padding: 0 2%;

  &-button {
    margin: 2% 0% 0% 2%;
    background-color: $button-color !important;
  }
  & img {
    height: 3rem;
    width: auto;
  }
  &-musicButtonArea {
    position: absolute;
    top: 13%;
    left: 3%;
  }
}
.brand-logo {
  font-size: 250% !important;
}

@media only screen and (max-width: 1025px) {
  .nav {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
  .brand-logo {
    font-size: 180% !important;
  }
}

//************************** Fields

.main {
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: rgba(0, 0, 0, 0.616);
}

.formField {
  width: 40%;
  height: 70%;
  border-radius: 3%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: $faded-white;
}
@media only screen and (max-width: 1025px) {
  .formField {
    width: 90%;
    height: 70%;
  }
}
.input-field {
  color: black;
  & label {
    color: black;
  }
}

//************************** forms

.inputArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &-inputField {
    width: 60%;
  }
  @media only screen and (max-width: 1025px) {
    &-inputField {
      width: 90%;
    }
  }
}
//************************** buttons
.buttonColor {
  background-color: $button-color !important;
}

.loginButtonArea {
  width: 18rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signUpFooter {
  width: 97%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  &-signUpButtonArea {
    grid-column: 1/3;
    grid-row: 1/2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &-signTextArea {
    grid-column: 1/3;
    grid-row: 2/3;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-begin;
  }
}

//************************** emotion route
.emoArea {
  width: 70%;
  height: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.emoIconField {
  display: flex;
  flex-direction: row;
  overflow: scroll;

  & a {
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 600;
  }
  & img {
    width: 15rem;
    margin: 1rem;
    height: auto;
  }
}
@media only screen and (max-width: 1025px) {
  .emoArea {
    height: 80%;
    position: relative;
    top: -50px;
  }

  .emoIconField {
    width: 90%;
    height: 90%;
    margin-top: 25%;
    flex-direction: column;
  }
}

//************************** details route

.detailsForm-title {
  font-size: 2.5rem;
  font-weight: 700;
}

.selector {
  width: 90%;
}

.detailsForm-textArea {
  width: 90%;
}

.detailsForm-buttonArea {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 1025px) {
  .detailsForm-title {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

//************************** Overview route

.overviewCalendarField {
  width: 70%;
  height: 80%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(7, 1fr);
  background-color: $faded-white;
  border-radius: 3%;

  &-titleArea {
    grid-column: 1/6;
    grid-row: 1/2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: 600;
  }

  &-calendarArea {
    grid-column: 1/4;
    grid-row: 2/8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-calendar {
    padding: 3%;
    width: 95%;
    height: 93%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    overflow-y: scroll;
  }
  &-emoCard {
    width: 5rem;
    height: 5rem;
    margin: 1.5%;
    margin-bottom: 13%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  &-buttonArea {
    grid-column: 4/6;
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & img {
      width: 90%;
      height: auto;
    }
  }
}
@media only screen and (max-width: 1025px) {
  .overviewCalendarField {
    width: 93%;
    height: 80%;
    display: grid;
    grid-template-columns: 1, 1fr;
    grid-template-rows: repeat(8, 1fr);

    &-titleArea {
      width: 118%;
      height: 93%;
      grid-column: 1/5;
      grid-row: 1/2;
      font-size: 1.5rem;
    }
    &-calendarArea {
      width: 100%;
      height: 98%;
      grid-column: 1/6;
      grid-row: 2/8;
    }
    &-calendar {
      padding: 3%;
      width: 95%;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }
    &-emoCard {
      width: 5rem;
      height: 5rem;
      margin: 1.5%;
      margin-bottom: 13%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }
    &-buttonArea {
      width: 120%;
      height: 93%;
      grid-column: 1/5;
      grid-row: 8/9;
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        width: 0%;
        height: auto;
      }
    }
  }
}

//************************** Modal
.modal {
  width: 45% !important;
  height: 45% !important;
  background-color: #fffefeaf !important;
  display: flex;
  justify-content: space-between;
  top: 30% !important;
  &-content {
    height: 76% !important;
  }
  &-grid {
    width: 100% !important;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    margin-top: 1rem;
  }
  &-progressBar {
    display: flex;
    justify-content: flex-end;
    grid-column: 1/3;
    grid-row: 1/2;
  }
  &-comment {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    grid-column: 1/3;
    grid-row: 2/5;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.226);
  }
  &-icon {
    grid-column: 3/5;
    grid-row: 1/5;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 100%;
      height: auto;
    }
  }

  &-footer {
    background-color: #fffefe00 !important;
    margin-top: 5%;
  }
}
@media only screen and (max-width: 1025px) {
  .modal {
    width: 90% !important;
    height: 30% !important;
    & h4 {
      font-size: 1.5rem;
    }
    &-content {
      height: 70% !important;
    }
    &-comment {
      font-size: 1rem;
    }
    &-footer {
      margin-top: 5%;
    }
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  background-color: $main-color !important;
}

.errorText {
  height: 3rem;
  width: 20rem;
  font-size: 0.6rem;
  font-weight: 600;
}
