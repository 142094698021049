$main-color:#223d6d65;
$button-color:#223d6d65;
$background-color: #367be2;
$faded-white:rgba(255, 255, 255, 0.603);

$cloud-color:whitesmoke;
$snow-color:rgb(220, 255, 206);
$canopy-color:rgb(101, 219, 248);
$drkCanopy-color:rgb(53, 121, 138);
$mdmCanopy-color:rgb(58, 164, 190);
$mdm2canopy-color:rgb(11, 118, 144);
$smallCanopy-color:rgb(166, 225, 240);

$start:'Lobster', cursive;